export enum PaymentMethod {
  PAYPAL = 'paypal',
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  SEPA = 'sepa',
  BALANCE = 'balance',
  POS = 'pos',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  SOFORTBANKING = 'sofortbanking',
  WALLEE = 'wallee',
  POSTFINANCE = 'postfinance',
  POSTFINANCE_CARD = 'postfinance_card',
  REKA = 'reka',
  TWINT = 'twint',
  LUNCHECK = 'luncheck',
  BONCARD = 'boncard',
  ALIPAY = 'alipay',
  WECHAT = 'wechat',
  CARD_WORLDLINE = 'worldline',
  WORLDLINE_RAW = 'worldline_raw',
  TAC = 'tac',
  CCV = 'ccv',
  ZERO_PAYMENT = 'zero_payment',
  WOLT = 'wolt',
  EC = 'ec',
  GIROCARD = 'girocard',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  VISA = 'visa',
  VISA_E = 'visa_e',
  VPAY = 'vpay',
  JCB = 'jcb',
  MAESTRO = 'maestro',
  OTHER = 'other',
  VR_SEPA = 'vr_sepa',
  BANK_TRANSFER = 'bank_transfer',
  DINERS = 'diners',
  DISCOVER = 'discover',
}
